.PagesWrapper {
    min-height: 100vh;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header"
        "main "
        "footer";
    align-content: space-between;
}

#header {
    grid-area: header;
}

#main {
    grid-area: main;
}

#footer {
    grid-area: footer;
}