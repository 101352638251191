.PathfinderVisualizer-container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
}

.PathfinderVisualizer-container-table {
    justify-content: center;
    border-spacing: 0px;
    width: 100%;
    height:100%;
    table-layout: fixed;

}

.PathfinderVisualizer-container-table tr {
    height: 20px;

}
.PathfinderVisualizer-container-table td {
    border-right: 1px solid var(--secondary-text-color);
    border-bottom: 1px solid var(--secondary-text-color);
    background-color: var(--white);
}


.PathfinderVisualizer-container-table .top {
    border-top: 1px solid var(--secondary-text-color);
}

.PathfinderVisualizer-container-table .left {
    border-left: 1px solid var(--secondary-text-color);

}

@keyframes wallAnimation {
    0%   {
        background-color: rgba(0, 0, 0, 0);
        transform: scale(.0);
    }
    25%  {
        background-color: rgba(0, 0, 0, 0.25);
        transform: scale(.25);
    }
    50%  {
        background-color: rgba(0, 0, 0, 0.5);
        transform: scale(.5);
    }
    75%  {
        background-color: rgba(0, 0, 0, 0.75);
        transform: scale(.5);
    }
    100% {
        background-color: rgba(0, 0, 0, 1);
        transform: scale(1.0);
    }
}

.PathfinderVisualizer-container-table .wall {
    background-color: black;
    animation-name: wallAnimation;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
}