.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    text-align: center;
    gap: 15px;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    padding: 10px 10px;
    border-radius: 10px;
    margin-top: 10px;
    z-index: 1;
}

.dropdown-title {
    display: flex;
    align-items: center;
    gap: 5px;
}
.dropdown-title svg {
    fill: var(--primary-text-color);
}
.dropdown-title:hover, .dropdown-title:hover svg {
    color: var(--primary-text-hover-color);
    fill: var(--primary-text-hover-color);
}