/*----------------tree-visualizer-scroll----------*/

.tree-visualizer-scroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}
.tree-visualizer-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e4e4e4;
}
.tree-visualizer-scroll::-webkit-scrollbar-thumb {
    background: #212121;
    border-radius: 10px;
    transition: 0.5s;
}
.tree-visualizer-scroll::-webkit-scrollbar-thumb:hover {
    background: #d5b14c;
    transition: 0.5s;
}

/*----------------tree-visualizer-tree----------*/
.tree-visualizer-body{
    white-space: nowrap;
    overflow-y: hidden;
    margin: auto;
    min-height: 500px;
    padding-top: 10px;
    text-align: center;
}
.tree-visualizer-tree{
    display: inline-block;
}
.tree-visualizer-tree ul {
    padding-top: 20px;
    position: relative;
    padding-left: 0px;
    display: flex;
    justify-content: center;
}
.tree-visualizer-tree li {
    float: left; text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
}
.tree-visualizer-tree li::before, .tree-visualizer-tree li::after{
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 2px solid #ccc;
    width: 50%;
    height: 18px;
}
.tree-visualizer-tree li::after{
    right: auto; left: 50%;
    border-left: 2px solid #ccc;
}
.tree-visualizer-tree li:only-child::after, .tree-visualizer-tree li:only-child::before {
    display: none;
}
.tree-visualizer-tree li:only-child{
    padding-top: 0;
}
.tree-visualizer-tree li:first-child::before, .tree-visualizer-tree li:last-child::after{
    border: 0 none;
}
.tree-visualizer-tree li:last-child::before{
    border-right: 2px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
}
.tree-visualizer-tree li:first-child::after{
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
}
.tree-visualizer-tree ul ul::before{
    content: '';
    position: absolute; top: 0; left: 50%;
    border-left: 2px solid #ccc;
    width: 0; height: 20px;
}
.tree-visualizer-tree li a{
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.tree-visualizer-tree li a:hover+ul li::after,
.tree-visualizer-tree li a:hover+ul li::before,
.tree-visualizer-tree li a:hover+ul::before,
.tree-visualizer-tree li a:hover+ul ul::before{
    border-color:  #fbba00;
}

/*--------------memeber-card-design----------*/
.member-view-box{
    padding:0px 20px;
    text-align: center;
    border-radius: 4px;
    position: relative;
}
.member-image{
    width: 60px;
    position: relative;
}
.member-image img{
    width: 60px;
    height: 60px;
    border-radius: 6px;
    background-color :#000;
    z-index: 1;
}
