.button-circle {
    color: var(--secondary-text-color);
    border: 1px solid var(--secondary-text-color);
    background-color: var(--edge-background-color);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
