.markdown-body {
    color: var(--primary-text-color);
}

.markdown-body p {
    line-height: 1.5;
    margin: 30px 0;
    font-size: var(--is-size-6);
}

.markdown-body code, kbd, pre, samp {
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}

.markdown-body pre {
    padding: 13px;
    font-size: 13px;
    line-height: 1.5;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.markdown-body pre code {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    background: #f9f9f9;
}

.markdown-body h1, h2, h3, h4, h5, h6 {
    font-weight: var(--has-text-weight-semibold) !important;
    border: none;
}

.markdown-body h1 {
    font-size: var(--is-size-2);
}

.markdown-body h2 {
    font-size: var(--is-size-3);
}