#sidebar {
    padding: 20px 5px;
    max-width: 200px;
    height: 100%;
    background-color: var(--secondary-background-color);;
    font-size: var(--is-size-7);
    font-weight: var(--has-text-weight-semibold);


    display: flex;
    flex-direction: column;
    gap: 20px;

}