.link:hover{
    color: var(--primary-text-hover-color)
}

.link svg{
    fill: var(--primary-text-color);
    width: 50px;
    height: 50px;
}

.link svg:hover{
    fill: var(--primary-text-hover-color);
    width: 50px;
    height: 50px;
}
