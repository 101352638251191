#header {
    background-color: var(--edge-background-color);
    color: var(--primary-text-color);
    border-bottom: 1px solid var(--primary-border-color);
    padding: 0 20px;
    height: 90px;
    position: sticky;
    top: 0;
    transition: height 0.5s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#header .image {
    padding-top: 10px;
    top: 10px;
    left: 45%;
    position: absolute;
    transition: opacity 0.5s, padding-top 0.5s;
    opacity: 1;
}

.header-smaller {
    height: 60px !important;
}

.header-smaller .image {
    opacity: 0 !important;
    padding-top: 0 !important;
}

@media only screen and (max-width: 600px) {
    #header {
        height: 60px;
    }
    #header .image{
        padding-top: 26px;
        left: 40%;
    }
    #header .image #Profile{
        width: 50px;
        height: 50px;
    }
}