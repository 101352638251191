.ArraySorting-container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
}
.ArraySorting-container-element {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
}

div[id^='ArraySorting-element-']{
    height: 100px;
    min-width: 2px;
    max-width: 50px;
    width: 100%;
    background-color: #8f5536;
}
