#footer {
    padding-top: 0;
    background-color: var(--edge-background-color);
}

#footer #footer-icon-row{
    padding: 30px 42% 20px;
    display: flex;
    justify-content: space-between;
}

#footer #footer-text-row{
    color: var(--secondary-text-color);
    padding: 0 0 30px;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    #footer #footer-icon-row{
        padding: 30px 25% 20px;
    }

}