:root {
  --edge-background-color: #F5F5F5;
  --secondary-background-color: #e8e8e8;
  --primary-text-color: #404040;
  --secondary-text-color: #808080;
  --primary-text-hover-color: #0085A1;
  --primary-border-color: #EAEAEA;
  --black: #000000;
  --white: #FFFFFF;

  --is-size-0: 84px;
  --is-size-1: 54px;
  --is-size-2: 44px;
  --is-size-3: 32px;
  --is-size-4: 26px;
  --is-size-5: 22px;
  --is-size-6: 18px;
  --is-size-7: 16px;

  --has-text-weight-bold: 900;
  --has-text-weight-semibold: 800;

  --primary-font-family: 'Lora';

  --icon-location: url(/src/components/atoms/Icons/Location/MyLocation.svg);
  --icon-drop-location: url(/src/components/atoms/Icons/DropLocation/dropLocation.svg);
  --icon-where-to-go-location: url(/src/components/atoms/Icons/WhereToGoLocation/whereToGo.svg);
}

body {
  margin: 0;
  color: var(--primary-text-color);
  font-family: var(--primary-font-family), 'Droid Sans', -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.remove-all-styles {
  all: revert;
}

.is-size-0 {
  font-size: var(--is-size-0)
}

.is-size-1 {
  font-size: var(--is-size-1)
}

.is-size-2 {
  font-size: var(--is-size-2);
}

.is-size-3 {
  font-size: var(--is-size-3);
}

.is-size-4 {
  font-size: var(--is-size-4);
}

.is-size-5 {
  font-size: var(--is-size-5);
}

.is-size-6 {
  font-size: var(--is-size-6);
}

.is-size-7 {
  font-size: var(--is-size-7);
}

.has-text-weight-bold {
  font-weight: var(--has-text-weight-bold);
}

.has-text-weight-semibold {
  font-weight: var(--has-text-weight-semibold);
}

.has-text-normal-bold {
  font-weight: normal;
}

.link {
  text-decoration: none;
  color: inherit;
}

.icon-location {
  background-image: var(--icon-location);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

}

.icon-drop-location {
  background-image: var(--icon-drop-location);
  background-repeat: no-repeat;
  background-position: center;
  background-size:contain;
}

.icon-home-location {
   background-image: var(--icon-where-to-go-location);
   background-repeat: no-repeat;
   background-position: center;
   background-size:contain;
 }
